var abstd = window["AnalyticsBuilder"];
window["AnalyticsBuilder"] = function (options) {
    var self = this, type = -1, instance = s4();

    if (options.trackers === undefined || options.trackers === '')
        options.trackers = [];
    else if (typeof options.trackers === "string")
        options.trackers = [options.trackers];

    self._log = function (text, data) {
        return;
    }

    //self.addProduct = function (product) {
    //    self._log('addProduct', product);

    //    if (product.prop && product.prop.constructor === Array) {
    //        for (var i in product)
    //            runGA('ec:addProduct', product[i]);
    //    } else
    //        runGA('ec:addProduct', product);

    //    return self;
    //};

    self.addImpression = function (product) {
        self._log('addImpression', product);
        type = 1;
        if (product.prop && product.prop.constructor === Array) {
            for (var i in product)
                runGA('ec:addImpression', product[i]);
        } else
            runGA('ec:addImpression', product);

        return self;
    };

    //self.addPromo = function (promo) {
    //    self._log('addPromo', promo);

    //    runGA('ec:addPromo', promo);
    //    return self;
    //};

    self.asPageView = function () {
        self._log('As Page View');

        type = 1;
        for (var i in options.trackers)
            try {
                runGA('config', ga.getAll()[i].get('trackingId'), { 'page_location': window.location.href });
            } catch (e) {
                //do nothing
            }
        return self;
    };

    self.asProductDetail = function (productType, product) {
        self._log('As Product Detail View');

        type = 1;
        runGA('ec:setAction', 'detail', product);
        //if (productType != null) {
        //    runGA('set', 'dimension1', productType);
        //}
        return self;
    };

    self.asAddToCart = function (product) {
        self._log('As Add To Cart');

        type = 2;
        runGA('ec:setAction', 'add', product);
        return self;
    };

    self.asRemoveFromCart = function (product) {
        self._log('As Remove From Cart');

        type = 3;
        runGA('ec:setAction', 'remove', product);
        return self;
    };

    self.asBeginCheckout = function (step) {
        self._log('As Begin Checkout', step);
        type = 4;
        runGA('ec:setAction', 'begin_checkout', step);
        return self;
    };

    //self.asCheckoutStep = function (step) {
    //    self._log('As Checkout Step', step);

    //    type = 4;
    //    runGA('ec:setAction', 'checkout', step);
    //    return self;
    //};

    self.asShippingOptionStep = function (step) {
        self._log('As Shipping option Step', step);

        type = 4;
        runGA('ec:setAction', 'shippingoption', step);
        return self;
    };

    self.asPaymentSelectStep = function (step) {
        self._log('As Payment Select Step', step);

        type = 4;
        runGA('ec:setAction', 'paymentselect', step);
        return self;
    };

    //self.asCheckoutOption = function (step) {
    //    self._log('As Checkout Option', step);

    //    type = 7;
    //    runGA('ec:setAction', 'checkout_option', step);
    //    return self;
    //}

    self.asPurchase = function (purchase) {
        self._log('As Purchase', purchase);

        type = 5;
        runGA('ec:setAction', 'purchase', purchase);
        return self;
    };

    //self.asCustomAction = function (action, data) {
    //    self._log('As Custom Action', [action, data]);

    //    type = 6;
    //    runGA('ec:setAction', action, data);
    //    return self;
    //};

    self.asClick = function (listType) {
        self._log('As Click', listType);

        type = 9;
        runGA('ec:setAction', 'click', {
            list: listType,
            event_label: listType
        });
        return self;
    }

    self.asCategory = function (cat, action, label, non_interaction) {
        self._log('add category', [cat, action, label, non_interaction]);

        runGA('category', action, {
            'event_category': cat,
            'event_label': label,
            'value': action,
            'non_interaction': non_interaction != undefined ? non_interaction : false,
            'send_to': window.GoogleAnalyticsUACodes != undefined && window.GoogleAnalyticsUACodes.length > 0 ? window.GoogleAnalyticsUACodes : ''
        });
        return self;
    };

    self.asEvent = function (name, variables) {
        self._log('add event', name, variables);
        var arg2 = '{';
        Object.entries(variables).forEach(function callback(key, index) {
            arg2 += JSON.stringify(key[0]) + ':' + JSON.stringify(key[1]) + '';
            if (index + 1 < Object.entries(variables).length) {
                arg2 += ','
            }
        });
        arg2 += '}';
        runGA('event', name, JSON.parse(arg2));
        return self;
    };

    self.send = function () {
        if (typeof gtag === 'undefined')
            return log('Can\'t send to GA, because Gtag isn\'t set');

        if (type === -1)
            return log('Please set the type before calling send()');

        if (window.Cookies.get("cookieControlPrefs") != undefined && window.Cookies.get("cookieControlPrefs").indexOf('statistics') == -1) {
            return log('Please set GDPR statistics on to continue');
            //} else {
            //    log('*GDPR-statistics: Google Analytics e-commerce funnel (send)');
        }
        if (window.Cookies.get("cookieControlPrefs") != undefined && window.Cookies.get("cookieControlPrefs").indexOf('marketing') == -1) {
            return log('Please set GDPR marketing on to continue');
        }

        var opt;
        var trackers = options.trackers;
        var cb = arguments[0];

        self._log('Sending to GA...');

        switch (type) {
            case 2:
                if (arguments.length > 1 || (typeof arguments[0] === "function")) {
                    cb = arguments.length > 1 ? arguments[1] : undefined;
                    opt = angular.extend(options["addToCart"], arguments[0]);
                } else
                    opt = options["addToCart"];
                break;
            case 3:
                opt = options["removeFromCart"];
                break;
            case 4:
                opt = options["checkoutStep"];
                break;
            case 6:
            case 10:
                opt = arguments[0];
                cb = arguments.length > 1 ? arguments[1] : undefined;
                break;
            case 7:
                opt = options["checkoutEvent"];
                break;
            case 8:
                opt = options["promoClick"];
                break;
            case 9:
                opt = options["click"];
            default:
                opt = options["defaultAction"];
                break;
        }
        if (opt !== undefined) {
            if (typeof trackers === 'string')
                send(trackers, opt, cb);
            else if (trackers.length !== undefined) {
                if (trackers.length) {
                    for (var i in trackers)
                        send(trackers[i], opt, cb);
                } else
                    send(trackers, opt, cb);
            } else
                send(trackers, opt, cb);
        } else
            console.warn('The command send() failed because no options were set');

    };

    function lowerize(obj) {
        Object.keys(obj).forEach(function (key, index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object 
            var value = obj[key];
            if (typeof value === 'string' || value instanceof String) {
                value = value.toLowerCase();
            }
            obj[key] = value;
            //return obj;
        });

        return obj;

        //Object.keys(obj).reduce((acc, k) => {
        //    var value = obj[k];
        //    if (typeof obj[k] === 'string' || obj[k] instanceof String) {
        //        value = obj[k].toLowerCase();
        //    }
        //    acc[k.toLowerCase()] = value;
        //    return acc;
        //}, {});
    }

    function runGA(name, arg1, arg2) {
        var preferences = $.gdprcookie.preference();
        if (preferences.indexOf('essential') > -1) {
            if (typeof gtag !== 'undefined') {
                if (window.store.length == 0) {
                    if (typeof arg2 === 'object' && !Array.isArray(arg2) && arg2 !== null) {
                        //all properties to lowercase
                        arg2 = lowerize(arg2);
                    }

                    //gtag way
                    switch (name) {
                        case 'ec:addImpression':
                            gtag('event', 'view_item_list', {
                                "items": [
                                    arg1
                                ]
                            });
                            log('gtag(\'event\', \'view_item_list\', { \"items\": ' + JSON.stringify([arg1]) + '});');
                            break;
                        case 'ec:setAction':
                            switch (arg1) {
                                case 'detail':
                                    gtag('event', 'view_item', {
                                        "value": arg2.price - arg2.discount,
                                        "currency": window.currencyCode,
                                        "items": [
                                            arg2
                                        ]
                                    });
                                    log('gtag(\'event\', \'view_item\', {\"value\":' + (arg2.price - arg2.discount).toString() + ',\"currency\":\"' + window.currencyCode + '\", \"items\": ' + JSON.stringify([arg2]) + '});');
                                    break;
                                case 'add':
                                    gtag('event', 'add_to_cart', {
                                        "items": [
                                            arg2
                                        ]
                                    });
                                    log('gtag(\'event\', \'add_to_cart\', { \"items\": ' + JSON.stringify([arg2]) + '});');
                                    break;
                                case 'remove':
                                    gtag('event', 'remove_from_cart', {
                                        "items": [
                                            arg2
                                        ]
                                    });
                                    log('gtag(\'event\', \'remove_from_cart\', { \"items\": ' + JSON.stringify([arg2]) + '});');
                                    break;
                                case 'begin_checkout':
                                    gtag('event', 'begin_checkout', arg2);
                                    log('gtag(\'event\', \'begin_checkout\', ' + JSON.stringify([arg2]) + ');');
                                    break;
                                case 'shippingoption':
                                    gtag('event', 'add_shipping_info', arg2);
                                    log('gtag(\'event\', \'add_shipping_info\', ' + JSON.stringify([arg2]) + ');');
                                    break;
                                case 'paymentselect':
                                    gtag('event', 'add_payment_info', arg2);
                                    log('gtag(\'event\', \'add_payment_info\', ' + JSON.stringify([arg2]) + ');');
                                    break;
                                case 'purchase':
                                    gtag('event', 'purchase', arg2);
                                    log('gtag(\'event\', \'purchase\', ' + JSON.stringify([arg2]) + ');');
                                    break;
                                case 'click':
                                    gtag('event', 'click', arg2);
                                    log('gtag(\'event\', \'click\', ' + JSON.stringify([arg2]) + ');');
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 'category':
                            gtag('event', arg1, arg2);
                            log('gtag(\'event\', \'' + arg1 +  '\', ' + JSON.stringify([arg2]) + ');');
                            break;
                        case 'event':
                            gtag('event', arg1, arg2);
                            log('gtag(\'event\', \'' + arg1 + '\', ' + JSON.stringify([arg2]) + ');');
                            break;
                        case 'config':
                            gtag('config', arg1, arg2);
                            log('gtag(\'config\', \'' + arg1 + '\', ' + JSON.stringify([arg2]) + ');');
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }

    function send(tracker, opt, cb) {
        function createSafeCallback() {
            var timeout;

            function done() {
                clearTimeout(timeout);

                if (cb !== undefined)
                    try {
                        cb();
                    } catch (e) { }
            }

            timeout = setTimeout(done, 350);
            return done;
        }

        createSafeCallback();
    }

    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    self._log('Started', options);
}

window["AnalyticsBuilder"].create = function (options) {
    var defaultOptions = {
        trackers: ['global', 'CountryTracker'],
        defaultAction: {
            type: 'pageview'
        },
        addToCart: {
            type: 'event',
            category: 'E-Commerce',
            action: 'Click',
            label: 'Add to cart'
        },
        removeFromCart: {
            type: 'event',
            category: 'E-Commerce',
            action: 'Click',
            label: 'Remove from cart'
        },
        checkoutEvent: {
            type: 'event',
            category: 'E-Commerce',
            action: 'Checkout',
            label: 'Option'
        },
        checkoutStep: {
            type: 'event',
            category: 'E-Commerce',
            action: 'Checkout',
            label: 'Step'
        },
        promoClick: {
            type: 'event',
            category: 'E-Commerce',
            action: 'Click',
            label: 'Promotion'
        },
        click: {
            type: 'event',
            category: 'E-Commerce',
            action: 'Click',
            label: 'Link'
        }
    };
    return new window["AnalyticsBuilder"](jQuery.extend(defaultOptions, options));
};

/*
 * Send pre-startup commands
 */
(function () {
    try {
        for (var i in abstd.q) {
            var ab = window["AnalyticsBuilder"].create(abstd.q[i].opt);
            for (var ii in abstd.q[i].q)
                ab[abstd.q[i].q[ii].f]
                    .apply(ab, abShim.q[ii].a);
        }
    } catch (e) {

    }
})();