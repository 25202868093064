var isSearching = false; // A flag to prevent multiple submits
var readyToSubmit = false; // The onsubmit handler must not handle its own submission 
var mouse_is_inside_megamenu = false;

$(document).ready(function () {
    Cookies.set('Culture', culture);

    if (document.URL.indexOf('review') === -1 && document.cookie.indexOf('accepts_cookies') === -1) {
        $('.cookiebar-wrapper').show();
        $('.cookiebar-close').click(function () {
            var d = new Date();
            d.setTime(d.getTime() + (732 * 24 * 60 * 60 * 1000));
            document.cookie = "accepts_cookies=1;expires=" + d.toUTCString(); + ";path=/" + culture + ";";

            $('.cookiebar-wrapper').hide();
        });
    }

    if (document.URL.indexOf('review') === -1 && document.cookie.indexOf('accepts_cookies') === -1) {
        $('#cookiePopupModal').modal({
            backdrop: 'static',
            keyboard: false
        })

        $('#cookiePopupModal a').click(function (e) {
            var url = $(this).attr('href') + '/popup';
            $.get(url, function (result) {
                $(".modal-body #moreInfo").html(result);
                $(".modal-body #moreInfo").css({ 'height': '400px', 'border': '1px solid #eee' });
            });
            return false;
        });

        $('#cookiePopupModal').modal('show');
        $('.cookiepopup-agreed').click(function () {
            var d = new Date();
            d.setTime(d.getTime() + (732 * 24 * 60 * 60 * 1000));
            document.cookie = "accepts_cookies=1;expires=" + d.toUTCString(); + ";path=/" + culture + ";";

            $('#cookiePopupModal').modal('hide');
        });
    }

    $("a.megamenu-trigger").each(function () {
        jsHref = $(this).attr("data-jshref");

        if (jsHref != "") {
            $(this).attr("href", jsHref);
        }
    });

    // header/brandswitch ONLOAD
    if (window.GDPRdefaultSettings != undefined) {
        HandleScrollingAndScaling(this);
    }

    $('tr[id^="cartItem-"] .remove > a').click(function (e) {
        e.preventDefault();

        var el = $(e.target).closest('tr')[0];
        window.location = $(el).find('.remove > a')[0].href;
        //suggestions from 55: This event is usually used for users clicking on blogs/articles. Therefore, it's unnecessary to send select_content event when users click add to cart or remove from cart.
        //AnalyticsBuilder.create(window.inTestMode ? { debug: true } : {}).addProduct({
        //    'name': $(el).find('.product-name-only a').text(),
        //    'quantity': parseInt($(el).find('.cartItem-quantity').text())
        //}).asRemoveFromCart().send(function () {
        //    window.location = $(el).find('.remove > a')[0].href;
        //});
    });

    $('.megamenu').hover(function () {
        mouse_is_inside_megamenu = true;
    }, function () {
        mouse_is_inside_megamenu = false;
    });


    $('#cart-and-userdata .country a').click(function (e) {
        $('.megamenu.fullscreen').toggleClass('opened');

        if ($('.menu-mobile').hasClass('menu-mobile-visible')) {
            $('.menu-mobile').removeClass('menu-mobile-visible');
        } else {
            $('.overlay').toggleClass('overlay-active');
        }

        $('body').toggleClass('no-scroll');

        e.preventDefault();
        return true;
    });


    $('.overlay').on('click', function () {
        if ($($('.megamenu.fullscreen').hasClass('opened'))) {
            $('.megamenu.fullscreen').removeClass('opened');
            $('.overlay').removeClass('overlay-active');
            $('body').removeClass('no-scroll');
        }
    });

    $("body").mouseup(function (e) {
        if (!mouse_is_inside_megamenu && !$(e.target).hasClass('megamenu-trigger')) {
            if ($('.megamenu-active').length > 0) {
                var code = $('.megamenu-active').attr('id').replace('megamenu', '');
                ToggleMegaMenu(code);
            }
            //$(".megamenu-active").slideUp();
            //$(".megamenu-active").removeClass("megamenu-active");
        }
    });

    $('#cacheModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var recipient = button.data('content') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this)
        modal.find('.modal-body').html(recipient);
    });

    $('.modal').on('shown.bs.modal', function (e) {
        var target = $(e.target);
        if (target.length > 0 && target[0].id != 'cacheModal') {
            var body = target.children('.modal-dialog').children('.modal-content').children('.modal-body');

            if (target.data('iframe') != undefined && target.data('iframe') !== '' && body.children().length === 0) {
                var isMobile = findBootstrapEnvironment() === 'xs';

                var height = $(window).height() - (isMobile ? 142 : 120);
                body.css({
                    overflowY: isMobile ? 'scroll' : 'hidden',
                    overflowX: 'hidden',
                    height: isMobile ? height : 'blaat',
                    width: body.width()
                });

                var element = $('<iframe style="width: 100%; height: ' + height + 'px; border:none;" src="' + target.data('iframe') + '"></iframe>');

                $(window).on('resize', function () {
                    height = $(window).height() - (isMobile ? 142 : 120);
                    $(element).css({ height: height });
                });

                body.html(element);
            }
        }

        if ($(body).find('.lazy-loading').length > 0) {
            //Trigger jquery.appear event to find and show unloaded images.
            $.force_appear();
        }

        //$('.modal-backdrop').css('z-index','auto !important');
    });

    $(".fancybox").not('.fancybox-imageswiper').each(function (key, value) {
        $(value).fancybox({
            maxWidth: 1000,
            maxHeight: 1000,
            fitToView: true,
            width: '90%',
            height: '90%',
            autoSize: true,
            padding: 0,
            helpers: {
                overlay: {
                    locked: false
                }
            }
        });
    });

    $(".fancyboxSmall").each(function (key, value) {
        $(value).fancybox({
            maxWidth: 450,
            maxHeight: 420,
            fitToView: true,
            autoSize: true,
            padding: 0,
            helpers: {
                overlay: {
                    locked: false
                }
            }
        });
    });

    /************************************************
     *                                              *
     *              Bike comparison                 *
     *                                              *
     ************************************************/

    $(".compare-bar-wrapper .compare-bike").each(function () {

        var iScrollPos = 0;
        $(window).scroll(function () {
            var iCurScrollPos = $(this).scrollTop();
            if (iCurScrollPos > iScrollPos) {
                $('.compare-bar-wrapper').addClass('collapsed');
            }
            iScrollPos = iCurScrollPos;
        });
    });

    var compareTemplate =
        '<div class="compare-bike">' +
        '<button class="glyphicon glyphicon-remove remove" id="{id}" aria-label="Remove this bike from comparison"></button>' +
        '<div class="img-wrap">' +
        '<img alt="" src="{image}" />' +
        '</div>' +
        '<p class="name">{name}</p>' +
        '</div>';

    // Set's up the compare bar
    (function (storage, storageKey, template, culture) {
        "use strict"

        if (culture === 'global')
            return;

        // Doesn't support web storage API
        if (storage == undefined)
            return (console.warn.bind(console) || console.log.bind(console))
                ("Storage access denied: disabling compare functionaltity.");

        storageKey = storageKey + "_" + culture;

        var storeData = [];
        var store = window["compareStore"] = window["compareStore"] || {
            add: function (data) {
                if (!data)
                    return;

                if ($('.compare-bar-wrapper').hasClass('compare-bar-hidden')) {
                    $('.compare-bar-wrapper').removeClass('compare-bar-hidden');
                }

                if (storeData.length === 3) {
                    if ($('.compare-bar-wrapper').hasClass('collapsed'))
                        $('.compare-bar-wrapper').removeClass('collapsed');
                    return;
                }

                if (!this.__contains(data.id)) {
                    storeData.push(data);
                    storage.setItem(storageKey, JSON.stringify(storeData));
                }

                this.__render();

                if ($('.compare-bar-wrapper').hasClass('collapsed')
                    && !$('.compare-bar-wrapper').hasClass('no-use')
                    && !$('.compare-bar').hasClass('add-animation')) {
                    $('.compare-bar').addClass('add-animation');
                    setTimeout(function () {
                        $('.compare-bar').removeClass('add-animation');
                        HandleCompareSectionExpandedAttribute();
                    }, 1000);
                }

                ShowScreenReaderOnlyDialog('compare', 'add');

            },
            remove: function (id) {

                for (var i in storeData) {
                    if (storeData[i]["id"] === id) {
                        storeData.splice(i, 1);
                        storage.setItem(storageKey, JSON.stringify(storeData));

                        this.__render();
                    }
                }

                if (storeData.length < 2) {
                    $('.compare-bar .btn-compare').addClass('disabled');
                    $('.compare-bar .btn-compare').prop("disabled", true);
                }

                HandleCompareSectionExpandedAttribute();
            },
            get: function () {
                var ids = [];
                for (var i in storeData)
                    ids.push((storeData[i]["globalcatalogue"] === "True" ? "gc" : "lc") + "@" + storeData[i]["id"]);
                return ids;
            },
            getItem: function () {
                var ids = [];
                for (var i in storeData)
                    ids.push(storeData[i]);
                return ids;
            },
            __contains: function (id) {
                for (var i in storeData)
                    if (storeData[i]["id"] === id)
                        return true;
                return false;
            },
            __render: function (init) {
                if (storeData.length > 0) {
                    $('.compare-bar-wrapper').removeClass('compare-bar-hidden');
                }

                for (var i = 0; i <= 3; i++) {
                    var data = storeData[i];
                    if (data !== undefined)
                        $('#compare-' + (i + 1))
                            .html(template
                                .replace(/{id}/, data["id"])
                                .replace(/{name}/, data["name"])
                                .replace(/{name}/, data["name"])
                                .replace(/{image}/, data["image"]));
                    else
                        $('#compare-' + (i + 1)).html('');
                }

                $('.compare-bar .remove').click(function (e) {
                    store.remove(Number(e.target.id));
                });

                // set count
                $('#compare-count').html(storeData.length);

                if (storeData.length > 0) {
                    setTimeout(function () {
                        $('.compare-bar-wrapper').removeClass('no-use' + (!init && storeData.length === 1 ? ' collapsed' : ''));
                        $('.skip-to-compare-wrapper').removeClass('no-use');
                        HandleCompareSectionExpandedAttribute();
                    }, 100);
                } else if (!$('.compare-bar-wrapper').hasClass('no-use')) {
                    $('.compare-bar-wrapper').addClass('no-use').addClass('collapsed');
                    $('.skip-to-compare-wrapper').addClass('no-use');
                    setTimeout(function () {
                        $('.compare-bar-wrapper').addClass('compare-bar-hidden')
                    }, 500);
                }

                if (storeData.length > 1) {
                    $('.compare-bar .btn-compare').removeClass('disabled');
                    $('.compare-bar .btn-compare').prop("disabled", false);
                } else if ($('.compare-bar .btn-compare').hasClass('disabled')) {
                    $('.compare-bar .btn-compare').addClass('disabled');
                    $('.compare-bar .btn-compare').prop("disabled", true);
                }

                if (storeData.length === 3 && $('.compare-bar-wrapper').hasClass('collapsed') && !init) {
                    $('.compare-bar-wrapper').removeClass('collapsed');
                    HandleCompareSectionExpandedAttribute();
                }
            }
        };

        for (var i = 0; i < storage.length; i++) {
            var key = storage.key(i);
            if (key === storageKey) {
                storeData = JSON.parse(storage.getItem(key));
                store.__render(true);

                return;
            }
        }

        storage.setItem(storageKey, '[]');
    })((function (storageName) {
        try { return window[storageName] } catch (e) { }
    })("sessionStorage"), "Giant_ProductCompare", compareTemplate, culture);

    $('.btn-compare:not(#compare-bikes)').click(function (e) {

        e.preventDefault();

        var data = $(e.target).hasClass('glyphicon')
            ? $('.btn-compare').closest().data()
            : $(e.target).data();

        window.compareStore.add(data);

        trackEventGlobalAndLocalGA4($(this).data());

        // a360
        $(e.target).attr('aria-expanded', 'true');

        // SK 25-08 always show bar when adding items
        $('.compare-bar-wrapper').removeClass('collapsed');

        // and focus
        $('button#compare-header-button').focus();

        return false;
    });

    $('#compare-bikes').click(function (e) {
        var modelname = window.compareStore.getItem().map(function (x) { return x.ga4_model_name }).join(", ");
        trackEventGlobalAndLocalGA4({ 'ga4_name': 'final_compare', 'ga4_model_name': modelname });
        window.location = '/' + culture + '/comparison/' + window.compareStore.get().join('-');
    });

    $('.compare-bar .header').click(function () {
        $('.compare-bar-wrapper').toggleClass('collapsed');

        HandleCompareSectionExpandedAttribute();

    });

    $('#skip-to-compare').click(function () {
        $('.compare-bar-wrapper').toggleClass('collapsed');

        HandleCompareSectionExpandedAttribute();
    });

    var lastPos = 0;
    var onTimeout = false;
    $(window).scroll(function (e) {
        if (onTimeout) return;

        var pos = $(this).scrollTop();

        if (pos > lastPos && !$('.compare-bar-wrapper').hasClass('collapsed')) {
            $('.compare-bar-wrapper').addClass('collapsed');
            HandleCompareSectionExpandedAttribute();
        }
        lastPos = pos;

        // To prevent excessive performance loss
        onTimeout = true;
        setTimeout(function () {
            onTimeout = false;
        }, 50);
    });

    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        infobar: false,
        idleTime: false,
        buttons: [
            //"zoom",
            //"share",
            //"slideShow",
            //"fullScreen",
            //"download",
            "close"
        ],
        baseTpl: '<div class="fancybox-container" role="dialog" aria-modal="true" aria-label="Image gallery carousel" tabindex="-1">' +
            '<div class="fancybox-bg"></div>' +
            '<div class="fancybox-inner">' +
            '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
            '<div class="fancybox-toolbar">{{buttons}}</div>' +
            '<div class="fancybox-navigation">{{arrows}}</div>' +
            '<div class="fancybox-stage"></div>' +
            '<div class="fancybox-caption"><div class="fancybox-caption__body"></div></div>' +
            "</div>" +
            "</div>",
        btnTpl: {
            close:
                '<button data-fancybox-close class="fancybox-button fancybox-button--close" id="fancybox-close-btn" title="{{CLOSE}}" aria-label="Close">' +
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg>' +
                "</button>",
            smallBtn:
                '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" id="fancybox-close-btn" title="{{CLOSE}}" aria-label="Close">' +
                '<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg>' +
                "</button>"
        },
        afterLoad: function (instance, current) {
            current.$image.attr('alt', current.opts.$orig.find('img').attr('alt'));
        },
        afterShow: function (instance, current) {
            // Focus on close button
            $("#fancybox-close-btn").focus();
        }
    });

    $('[data-type="iframe"]').fancybox({
        toolbar: true,
        smallBtn: false,
        idleTime: false,
        iframe: {
            preload: false
        },
        baseTpl: '<div class="fancybox-container" role="dialog" aria-modal="true" tabindex="-1">' +
            '<div class="fancybox-bg"></div>' +
            '<div class="fancybox-inner">' +
            '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
            '<div class="fancybox-toolbar">{{buttons}}</div>' +
            '<div class="fancybox-navigation">{{arrows}}</div>' +
            '<div class="fancybox-stage"></div>' +
            '<div class="fancybox-caption"><div class="fancybox-caption__body"></div></div>' +
            "</div>" +
            "</div>",
        btnTpl: {
            close:
                '<button data-fancybox-close class="fancybox-button fancybox-button--close" id="fancybox-close-btn" title="{{CLOSE}}" aria-label="Close">' +
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg>' +
                "</button>",
            smallBtn:
                '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" id="fancybox-close-btn" title="{{CLOSE}}" aria-label="Close">' +
                '<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg>' +
                "</button>"
        },
        afterShow: function (instance, current) {
            $('.fancybox-is-open').attr('aria-label', instance.$trigger[0].outerText);
        }
    })


    //$(".galleryzoom").fancybox({
    //    'zoomOpacity': true,
    //    'overlayShow': false,
    //    'zoomSpeedIn': 500,
    //    'zoomSpeedOut': 500,
    //    helpers: {
    //        overlay: {
    //            locked: false
    //        }
    //    },
    //    afterShow: function () {

    //        // focus to close link
    //        var objClose = $("a.fancybox-close"); 
    //        $(objClose).focus();

    //        // store opener id in hidden input
    //        var galleryItemId = this.element.attr("data-galleryitemid");
    //        $("input#fancyboxOpenerId").val(galleryItemId);

    //        // add class to body
    //        $("body").addClass("gallery-zoom-active");

    //        //A360
    //        openDialog($('.fancybox-wrap')[0]);

    //        //A360
    //        $('.fancybox-opened').attr('role', 'dialog');
    //        $('.fancybox-opened').attr('aria-modal', 'true');
    //        $('.fancybox-opened').attr('aria-label', 'Image Gallery');
    //    },
    //    afterClose: function () {
    //        // add class to body
    //        $("body").removeClass("gallery-zoom-active");
    //        // back to opener
    //        var openerId = $("input#fancyboxOpenerId").val();
    //        var openerElement = $("#gallery .thumb a[data-galleryitemid='" + openerId + "']"); 
    //        if (openerElement != null) {
    //            $(openerElement.get(0)).focus();
    //        }

    //        //A360
    //        closeDialog();
    //    }

    //});

    //$(".fancybox").fancybox({
    //    afterShow: function (e) {
    //        openDialog($('.fancybox-wrap')[0]);
    //        // focus to close link
    //        var objClose = $("a.fancybox-close");
    //        $(objClose).focus();

    //        $('.fancybox-opened').attr('role', 'dialog');
    //        $('.fancybox-opened').attr('aria-modal', 'true');
    //        try {
    //            $('.fancybox-opened').attr('aria-label', $(this)[0].element[0].outerText);
    //        } catch (e) {
    //            //do nothing
    //        }
    //    },
    //    afterClose: function (e) {
    //        closeDialog();
    //        e.element[0].focus();
    //    }

    //}); 



    /* ACT LIKE TAB CLICK */
    $("a.act-like-tab-click").click(function (event) {

        // get target
        myHref = $(this).attr("href");

        // prevent anchor link
        event.preventDefault();

        // set link state
        $(".scrollmenu a").removeClass("active");
        // activate
        var linkId = myHref.replace("#", "");
        $(".scrollmenu a#scrollmenu-" + linkId).addClass("active");

        //scroll
        ScrollToTarget(myHref);

        // set focus to target
        $("div" + myHref).focus();
    });


    // 21-09-2023 - scroll to onload of URL with anchor
    if ($("body").hasClass("pb-page") && window.location.hash) {
        var myHash = window.location.hash;
        if (myHash.indexOf("#element-") > -1) {
            ScrollToTarget(myHash);
        }
    }

    /* HIGHLIGHTS sliding */
    $("button.highlight-link").click(function () {

        // get id of infoblock
        var myId = $(this).attr("data-id");
        var divId = "highlight-info-" + myId;
        var isVisible = $("div#" + divId).hasClass("highlight-info-active");

        // close all
        CloseHighlight(myId);

        if (isVisible) {
            // keep it that way
        } else {
            // open
            $(this).addClass("highlight-link-active");
            $("#" + divId).addClass("highlight-info-active");
            $(this).attr("aria-expanded", "true");
            $("body").addClass("has-active-highlight");
            $(this).text("X");
        }

    });

    $(".highlight-close").click(function () {
        var myId = $(this).attr("data-id");
        CloseHighlight(myId);
    });


    $(".highlight-link:not(.percentage-mode)").each(function () {

        var originalWidth = $(".content-wrapper-highlights .image img").attr("data-originalwidth");
        var imageWidth = $(".content-wrapper-highlights .image img").width();

        if (imageWidth < originalWidth) {

            var factor = (imageWidth / originalWidth);

            // get original positions
            var originalTop = $(this).attr("data-originaltop");
            var originalLeft = $(this).attr("data-originalleft");

            // define new position
            var newTopValue = originalTop * factor;
            var newLeftValue = originalLeft * factor;
            if (imageWidth == 500) {
                newLeftValue = newLeftValue + 30
            } else {
                newLeftValue = newLeftValue - 30;
            }

            // set position
            $(this).css('top', newTopValue);
            $(this).css('left', newLeftValue);

        }

    });



    /* CALLOUTS */
    $("button.callout-link").click(function () {

        var yPosLink = 0;

        // set all links to default
        $("button.callout-link").removeClass("callout-link-active");

        // get id of infoblock
        myId = this.id;
        divId = myId.replace("link", "info");

        // get rel
        myRel = $(this).attr("rel");

        // check if click to show or click to hide
        if ($("div#" + divId).is(":visible")) {
            $(this).removeClass("callout-link-active");
            // close this one
            $("div#" + divId).fadeOut('fast');
            $(this).text(myRel);
            // a360
            $(this).attr("aria-expanded", "false");
        }
        else {

            // add active
            $(this).addClass("callout-link-active");

            // hide all other info blocks
            $("div.callout-info").fadeOut();

            // show this one
            $("div#" + divId).fadeIn();

            // a360
            $(this).attr("aria-expanded", "true");

            // set position of infoblock
            yPosLink = $(this).position().top;

            var yPosDiv = yPosLink + 22;
            $("div#" + divId).css({ top: yPosDiv + 'px' });

            // change link text
            $(this).text("X");

            // change other link texts back to number
            $("button.callout-link").each(function () {
                loopId = this.id;
                numberText = $(this).attr("rel");
                if (loopId != myId) {
                    $(this).text(numberText);
                }
            });

        }

    });

    // SK: reposition callouts based on css image size
    $(".callout-link:not(.percentage-mode)").each(function () {

        var originalWidth = $(".content-wrapper-highlights .image img").attr("data-originalwidth");
        var imageWidth = $(".content-wrapper-highlights .image img").width();

        if (imageWidth < originalWidth) {

            var factor = (imageWidth / originalWidth);

            // get original positions
            var originalTop = $(this).attr("data-originaltop");
            var originalLeft = $(this).attr("data-originalleft");

            // define new position
            var newTopValue = originalTop * factor;
            var newLeftValue = originalLeft * factor;
            if (imageWidth == 500) {
                newLeftValue = newLeftValue + 30
            } else {
                newLeftValue = newLeftValue - 30;
            }

            // set position
            $(this).css('top', newTopValue);
            $(this).css('left', newLeftValue);

        }

    });

    var matchList = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('SmartSearchDto'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 100,
        remote: {
            //url: 'http://localhost:59019/api/smartsearch/search?searchTerm=%QUERY',
            url: '/' + culture + '/search/jsonsearch?keyword=%QUERY',
            wildcard: '%QUERY'
        }
    });
    matchList.initialize();

    $("input#search").typeahead({
        hint: false,
        minLength: 3
    },
        {
            templates: {
                suggestion: function (data) {
                    var item = data.Title;
                    var html = $('<ul></ul>');
                    var query = data._query;
                    try {
                        var i = item.toString().toLowerCase().indexOf(query.toLowerCase());
                    } catch (e) {

                    }
                    var len, leftPart, middlePart, rightPart, strong;
                    len = query.length;
                    while (i > -1) {
                        leftPart = item.substr(0, i);
                        middlePart = item.substr(i, len);
                        rightPart = item.substr(i + len);
                        strong = $('<strong></strong>').text(middlePart);
                        html
                            .append(document.createTextNode(leftPart))
                            .append(strong);
                        item = rightPart;
                        i = item.toString().toLowerCase().indexOf(query.toLowerCase());
                    }
                    var result = '<li role="option" id=searchResult-' + data.key + '>' + html.append(document.createTextNode(item)).html()
                    if (data.SubTitle.length > 0) { result += ' - ' + data.SubTitle }
                    result += "</li>";
                    return result;
                }
            },
            source: matchList.ttAdapter(),
            displayKey: 'Title',
            limit: 100000
        });

    $("input#search").bind('typeahead:selected', function (obj, item) {
        window.location = item.Url;
    });

    $('input#search').bind('typeahead:asyncreceive', function (ev, suggestion, result) {
        $('.results-count').html($('.tt-suggestion').length + ' items found');
        $('#search').attr('aria-expanded', true);
    });

    $('input#search').bind('typeahead:open', function (ev, suggestion, result) {

    });

    $('input#search').bind('typeahead:close', function (ev, suggestion, result) {
        $('#search').attr('aria-expanded', false);
    });

    $('input#search').bind('typeahead:cursorchange', function (ev, suggestion, result) {
        $(ev.target).attr("aria-activedescendant", "searchResult-" + suggestion.key);
    });

    $("body .header-search input").click(function () {
        $("body .header-search").addClass("header-search-active");
    });
    $("body a.hide-expanded-search").click(function () {
        $("body .header-search").removeClass("header-search-active");
    });


    $("header .header-search a.search-submit").click(function () {
        var inputValue = $("input#search").val();
        if (inputValue != "") {
            $("header .header-search form").submit();
        }
        else {
            $("body .header-search").addClass("header-search-active");
        }
    });

    $('.campaignPopover').popover();

    $('body').on('mouseover', '.campaignPopover', function (e) {
        $(this).popover('show');
    });

    $('body').on('click', '.orderhandlingcodePopover', function (e) {
        if ($(this).next('div.popover:visible').length) {
            $(this).popover('hide');
        } else {
            $(this).popover('show');
        }
    });

    $('.btn-enable-progress').click(function () {
        $(this).addClass('btn-run-progress');
    });

    $("a.toggle-video").click(function () {
        $(this).parent().parent().parent().addClass("content-wrapper-video-playing");
        $(this).hide();
        myId = this.id;

        // hide link
        iframeId = myId.replace("toggle-", "");

        // show iframe
        $("iframe#" + iframeId).show();

        // autoplay
        iframeSrc = $("iframe#" + iframeId).attr("src");
        $("iframe#" + iframeId).attr("src", iframeSrc + "&autoplay=1");

    });

    $("a.setMainImage").click(function () {
        // get new main image src
        var myRel = $(this).attr("rel");

        // get loopindex attr
        var loopIndex = $(this).data("loopindex");
        $('img[name="mainImage"]').parent().data('slide', loopIndex);
        objImage = $('img[name="mainImage"]')
            .attr('src', myRel);
    });

    $('.getContactForm').click(function () {
        $('#contactModal').modal('show');
    });


    $('.getstockalert').click(function () {
        $('#stockAlertModal #partnumberCode').val($(this).attr('data-partnumbercode'));
        $('#stockAlertModal').modal('show');
    });

    $('#sendStockAlert').click(function () {
        $('#stockAlertModal .alert').remove();
        $.ajax({
            type: 'POST',
            url: '/' + culture + '/shop/stockalert',
            data: { partnumberCode: $('#partnumberCode').val(), email: $('#Email').val(), zipcode: $('#Zipcode').val() },
            success: function (data) {
                if (data == "True") {
                    $('#stockAlertModal').modal('hide');
                } else {
                    $('#stockAlertModal .modal-body').prepend('<div class="alert alert-danger">' + data + '</div>');
                }
            }
        });
    });

    $("#inputFindLocalStock").keydown(function (event) {
        if (event.keyCode == 13) {
            $("#btnFindLocalStock").trigger("click");
            return false;
        }
    });

    $('body').on('click', '.btn-findLocalstock,#findLocalStock', function () {
        $('#ModalLocalStockFinder').modal('show');
    });


    if (isMobile()) {
        $('.panel-default').on('shown.bs.collapse', function (e) {
            var $panel = $(e.target).parent('.panel');
            var extraOffset = 68;

            setTimeout(function () {
                $('html,body').animate({
                    scrollTop: $panel.offset().top - extraOffset
                }, 750);
            }, 400);
        });
    }

    $('.panel-collapse').on('show.bs.collapse', function () {
        $('.panel-collapse').collapse('hide');
    })

    $('input[name="agreeToTermsAndConditions"]').change(function (e) {
        var filtered = $('input[name="agreeToTermsAndConditions"]').not(this);
        var val = this.checked;
        filtered.prop('checked', val);
    });

    $('.birthday').datetimepicker({
        viewMode: 'years',
        format: 'L',
        maxDate: new Date((new Date().getFullYear() - 10), 1, 1),
        locale: cultureFull
    });

    $('.birthdayShop').datetimepicker({
        viewMode: 'years',
        format: 'L',
        locale: cultureFull,
        minDate: new Date((new Date().getFullYear() - 119), 1, 1),
        maxDate: new Date()
    }).on('dp.change', function (e) {
        var element = angular.element($(this));
        var controller = element.controller();
        var scope = element.scope();
        scope.Cart.Order.BirthDateISO = e.date.format('YYYY-MM-DD');
        scope.$apply();
        $('#BirthDateISO').val(e.date.format('YYYY-MM-DD'));
    });

    $('.birthdayAccount').datetimepicker({
        viewMode: 'years',
        format: 'L',
        locale: cultureFull,
        minDate: new Date((new Date().getFullYear() - 119), 1, 1)
        // maxDate: today
    }).on('dp.change', function (e) {
        var element = angular.element($(this));
        var controller = element.controller();
        var scope = element.scope();
        scope.Account.BirthDateISO = e.date.format('YYYY-MM-DD');
        scope.$apply();
        $('#BirthDateISO').val(e.date.format('YYYY-MM-DD'));
    });

    $('.birthdayRegistration').datetimepicker({
        viewMode: 'years',
        format: 'L',
        locale: cultureFull,
        minDate: new Date((new Date().getFullYear() - 119), 1, 1),
        maxDate: new Date()
    }).on('dp.change', function (e) {
        var element = angular.element($(this));
        var controller = element.controller();
        var scope = element.scope();
        scope.Registration.Registration.BirthDateISO = e.date.format('YYYY-MM-DD');
        scope.$apply();
        $('#BirthDateISO').val(e.date.format('YYYY-MM-DD'));
    });

    $('.datetimeRegistration').datetimepicker({
        maxDate: new Date(),
        format: 'L',
        locale: cultureFull,
        minDate: new Date((new Date().getFullYear() - 119), 1, 1),
        maxDate: new Date()
    }).on('dp.change', function (e) {
        var element = angular.element($(this));
        var controller = element.controller();
        var scope = element.scope();
        scope.Registration.Registration.BuyDateISO = e.date.format('YYYY-MM-DD');
        scope.$apply();
        $('#BuyDateISO').val(e.date.format('YYYY-MM-DD'));
    });

    $('.senddate').datetimepicker({
        format: 'L',
        locale: cultureFull,
        minDate: new Date()
    }).on('dp.change', function (e) {
        var element = angular.element($(this));
        var controller = element.controller();
        var scope = element.scope();
        scope.GiftcardSenddate = e.date.format('YYYY-MM-DD');
        scope.$apply();
        $('#senddate').val(e.date.format('YYYY-MM-DD'));
    });

    $('.datetime').datetimepicker({
        maxDate: new Date(),
        format: 'L',
        locale: cultureFull
    });

    $('a#toggle-storefilters').click(function () {
        $("div.storefilters div").slideToggle();
    });


    $('a#clearfilters').click(function () {
        // remove filters by trigger click on hidden button because its outside the form
        $("button#reset-filters").trigger("click");
        $("input#hasstock-webshop").prop('checked', false);
        // show all tiles
        $("#productsContainer .mix").show();
        // count again
        setItemCount();
        // reset url
        window.history.pushState('', '', window.location.origin + window.location.pathname + window.location.search);
    });
    $("#number-of-results #countActiveRows").each(function () {
        setItemCount();
    });


    //new SwipeGallery('.swipe-base', '.gallery-item');

    $(".paymentContainer input.payment-option-radio").change(function () {

        // get value
        clickedValue = $(this).val();

        // toggle classes
        $(".paymentOption").addClass("paymentOption-inactive");
        $(".paymentOption-" + clickedValue).removeClass("paymentOption-inactive");

    });


    $(".faq-boxes li a").click(function () {

        // remove active
        $(".faq-box").removeClass("active");
        $(".faq-box").attr("aria-selected", "false");
        $(".faq-box").attr("tabindex", "-1");

        $(".faq-answer").hide();

        $(this).addClass("active");
        myId = this.id;

        divId = myId.replace("faq-box", "faq-answer");

        // set active index
        myIndex = $(this).attr("data-index");
        activeFaqIndex = myIndex;

        // set aria selected
        $(this).attr("aria-selected", "true");

        // 20-04-2021 a360: remove tabindex attribute on active tab
        $(this).removeAttr("tabindex");

        // get url
        var url = $(this).attr("data-url");
        if (url != "") {
            window.open(url, 'FAQ');
        }
        else {
            // slide
            $("#" + divId).slideDown();

            // scroll
            var newPosition = $(".answers").offset().top;
            $('html, body').animate({
                scrollTop: newPosition - 150
            }, 1000);
        }

    });


    $('form#Geocoder').submit(function (e) {
        //log($(this).serialize());
        if ($('#latlng').val() != '') {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    });


    $("button.toggle-unit").click(function () {

        // toggle button class
        $("button.toggle-unit").toggleClass("toggle-unit-active");
        $("button.toggle-unit").toggleClass("selected");

        // aria pressend and selected on false
        $("button.toggle-unit").attr("aria-pressed", "false");
        $("button.toggle-unit").attr("aria-selected", "false");

        // put all selected buttons to aria-pressed and aria-selected true 
        $("button.toggle-unit.selected").attr("aria-pressed", "true");
        $("button.toggle-unit.selected").attr("aria-selected", "true");

        // toggle values for unit
        $("span.value-mm").toggle();
        $("span.value-inch").toggle();
        $("span.inch-label").toggle();
        $("span.mm-label").toggle();
        $("span.value-km").toggle();
        $("span.value-miles").toggle();

    });

    // show swipe hand symbol on mobile
    $(".icon-hand-swipe").each(function () {
        var myHandIcon = this;

        $(".icon-hand-swipe-container").scroll(function () {
            // get id
            var divId = this.id;
            // get scroll value
            var scrollValue = $(this).scrollLeft();
            if (scrollValue > 0) {
                // wait and fade out
                setTimeout(function () {
                    $("#" + divId + " .icon-hand-swipe").addClass("fadeout");
                }, 100);
            }
        });

    });


    $('a#link-serviceappointmentmodal').click(function () {
        var currentWindowHeight = $(window).height();
        $("iframe#planitiframe").css('height', currentWindowHeight * 0.75 | 0);
    });

    $('#contactForm').on('submit', function (e) {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute(window.GoogleRecaptchaSiteKey, { action: 'social' })
                .then(function (token) {
                    $('#recaptchaToken').val(token);
                    $('#contactSubmitButton').addClass('btn-run-progress');
                    $('#contactSubmitButton').attr('disabled', 'disabled');
                    e.currentTarget.submit();
                });
        });
    });

    $('#warrantyCheckForm').on('submit', function (e) {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute(window.GoogleRecaptchaSiteKey, { action: 'social' })
                .then(function (token) {
                    $('#recaptchaToken').val(token);
                    e.currentTarget.submit();
                });
        });
    });

    $('#newsletterForm').on('submit', function (e) {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute(window.GoogleRecaptchaSiteKey, { action: 'social' })
                .then(function (token) {
                    $('#recaptchaToken').val(token);
                    e.currentTarget.submit();
                });
        });
    });

    $('#resetpasswordRequestCheckForm').on('submit', function (e) {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute(window.GoogleRecaptchaSiteKey, { action: 'social' })
                .then(function (token) {
                    $('#recaptchaToken').val(token);
                    e.currentTarget.submit();
                });
        });
    });

    $('#addSubscriberAjax').on('click',
        function () {
            $('.addSubscriberAjaxMessage').remove();
            var valid = true;
            if ($('#optin').is(':checked') == false) {
                $('#addSubscriberContainer').after('<p id="checkboxErrorDescription" aria-describedby="optin" class="addSubscriberAjaxMessage alert alert-danger">' + lblOptInCheckboxEmpty + '</p>');
                valid = false;
            }

            if ($('#MailAddress').val() == '' || $('#MailAddress').val().match(/.+\@.+\..+/) == false) {
                $('#addSubscriberContainer').after('<p id="emailErrorDescription" class="addSubscriberAjaxMessage alert alert-danger">' + window.lblInvalidEmail + '</p>');
                $('#MailAddress').focus();
                valid = false;
            }

            if (valid) {
                //honeypot is empty. So probably no bot
                //append form target and submit the form
                $('#addSubscriberAjax').addClass('btn-run-progress');

                $.ajax({
                    type: 'POST',
                    url: '/' + culture + '/newsletter/subscribeAjax',
                    data: { email: $('#MailAddress').val() },
                    success: function (data) {
                        if (data.result == true) {
                            $('#addSubscriberContainer').after('<p class="addSubscriberAjaxMessage alert alert-success">' + data.message + '</p>')

                            //push identity to Klaviyo
                            //we kunnen hier niet identify pushen, omdat dan getracked wordt zonder opt-in consent van de subscriber
                            //var preferences = $.gdprcookie.preference();
                            //if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
                            //    klaviyo.push(['identify', {
                            //        // Change the line below to dynamically print the user's email.
                            //        '$email': $('#MailAddress').val(),
                            //    }]);
                            //    //var _learnq = _learnq || [];
                            //    //_learnq.push(['identify', { '$email': $scope.Account.Email }]);
                            //}
                        } else {
                            $('#addSubscriberContainer').after('<p id="emailErrorDescription" aria-describedby="MailAddress" class="addSubscriberAjaxMessage alert alert-danger">' + data.message + '</p>')
                        }
                        $('#addSubscriberAjax').removeClass('btn-run-progress');
                    }
                });
            }
        });

    // toggle mobile menu
    $('button#toggle-menu').click(function () {

        var isAlreadyOpen = $("body").hasClass('show-mobile-menu');
        if (isAlreadyOpen) {
            // CLOSE MOBILE MENU
            $(this).removeClass('open');
            $(this).attr("aria-expanded", "false"); //A360 Issue 340: fix for screenreaders
            // add class to body
            $("body").removeClass('show-mobile-menu');
            // show overlay
            $(".overlay").removeClass('overlay-active');

        }
        else {
            // OPEN MOBILE MENU
            $(this).addClass('open');
            $(this).attr("aria-expanded", "true"); //A360 Issue 340: fix for screenreaders
            // add class to body
            $("body").addClass('show-mobile-menu');
            // show overlay
            $(".overlay").addClass('overlay-active');

        }

    });



    // multiple images arrow 
    $("ul#productImagelist").on("scroll", function () {
        $(".arrow-multiple-images").fadeOut("slow");
    });

    // header+brandswitch ONSCROLL
    $(window).on("scroll", function () {
        HandleScrollingAndScaling(this);
    });
    $(window).on('resize', function () {
        HandleScrollingAndScaling(this);
    });

    // megamenu
    $(".toggle-megamenu").click(function () {

        var menuId = $(this).attr("data-menuid");
        var isVisible = $('#' + menuId).is(':visible');

        // hide others
        $(".toggle-megamenu").attr("aria-expanded", "false");
        $(".megamenuactive").removeClass("megamenuactive");
        $(".toggle-megamenu").removeClass("active");

        if (isVisible) {
            // hide megamenu
            $("#" + menuId).removeClass("megamenuactive");
            // hide overlay
            $(".overlay").removeClass("overlay-active");
            // remove body megameny class 
            $("body").removeClass("megamenu-open");

        } else {
            // show megamenu
            $("#" + menuId).addClass("megamenuactive");
            // show overlay
            $(".overlay").addClass("overlay-active");
            // add class to body for mobile scrolling
            $("body").addClass("megamenu-open");
            // add active class to link itself
            $(this).addClass("active")
            // aria 
            $(this).attr("aria-expanded", "true");
            // add focus
            if (menuId == "megamenusearch") {
                $("#megamenusearch form input").focus();
            }
        }
    });

    $(".close-megamenu a").click(function () {
        $(".megamenuactive").removeClass("megamenuactive");
        // body
        $("body").removeClass("megamenu-open");
        // hide overlay
        $(".overlay").removeClass("overlay-active");
    });

    $('[data-analytics-link]').click(function (e) {
        var preferences = $.gdprcookie.preference();
        if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
            try {
                if (!ga.loaded)
                    return;
                e.preventDefault();

                var $link;
                if ($(e.target).is('[data-analytics-link]'))
                    $link = $(e.target);
                else
                    $link = $(e.target).closest('[data-analytics-link]');


                window["AnalyticsBuilder"].create(window.inTestMode ? { debug: true } : {})
                    //.addProduct(createProduct($link.data()))
                    .asClick($link.data('analyticsLink'))
                    .send(function () {
                        window.location = $link[0].href;
                    });
                log("*GDPR-statistics + GDPR-marketing: data-analytics-link");
            } catch (e) {
                //speciaal voor Brave browser
                log("*GDPR-statistics + GDPR-marketing BLOCKED: data-analytics-link");
            }
        }
    });

    //Verplaatst naar functions.js HandleScrollingAndScaling
    //$('[data-analytics-impression]').each(function (i, e) {
    //    var preferences = $.gdprcookie.preference();
    //    if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
    //        var impression = createProduct($(e).data());
    //        impression['list'] = $(e).data('analyticsImpression');
    //        impression['position'] = i + 1;
    //        impression['list_name'] = $(e).data('analyticsImpression');
    //        impression['list_position'] = i + 1;
    //        impression['price'] = $(e).data('analyticsPrice');
    //        impression['id'] = $(e).data('analyticsId');

    //        try {
    //            window["AnalyticsBuilder"].create(window.inTestMode ? { debug: true } : {})
    //                .addImpression(impression)
    //                .send();
    //            log("*GDPR-statistics + GDPR-marketing: data-analytics-impression");
    //        } catch (e) {
    //            //speciaal voor Brave browser
    //            log("*GDPR-statistics + GDPR-marketing BLOCKED: data-analytics-impression");
    //        }

    //    }
    //});

    isMobile = function () {
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");
        return isMobile.matches ? true : false
    }

    // desktop - so replace placeholder div with embed
    $(".banner-video-enable-mobile-switch").each(function () {

        // get image 
        var dataVideoPlaceholderImage = $(this).attr("data-videoplaceholderimage");
        var dataVideoPlaceholderImageDesktop = $(this).attr("data-videoplaceholderimagedesktop");
        var dataVideoProvider = $(this).attr("data-videoprovider");
        var dataUseMobilePlaceholderImage = $(this).attr("data-usemobileplaceholderimage");

        if (isMobile() && dataUseMobilePlaceholderImage == "True") {
            // set class
            $(this).addClass("banner-enable-mobile-image");
            // set image
            $(this).css("background-image", "url('" + dataVideoPlaceholderImage + "')");
        }
        else {

            var dataVideoUrl = $(this).attr("data-videourl");
            var dataVideoCode = $(this).attr("data-videocode");
            var dataVideoCodeMobile = $(this).attr("data-videocode-mobile");
            var dataAutoPlay = $(this).attr("data-autoplay");
            var dataAltText = $(this).attr("data-alttext");
            var dataEnableCaptions = $(this).attr("data-enablecaptions");
            var dataIframeTitle = $(this).attr("data-iframetitle");
            var autoplayValue = dataAutoPlay != "" ? dataAutoPlay : "1";

            if (dataVideoProvider == "Youtube") {
                // ---------------Youtube ------------------

                var youtubeEmbedUrl = "https://www.youtube.com/embed/" + dataVideoCode + "?controls=0&showinfo=0&rel=0&autoplay=" + autoplayValue + "&mute=0&loop=1&cc_load_policy=0&enablejsapi=1&origin=https%3A%2F%2Fwww.giant-bicycles.com&widgetid=1";
                var videoHtml = '';
                videoHtml += '<div class="video-wrapper-outer">';
                videoHtml += '<div class="video-wrapper-inner">';
                videoHtml += '<div class="video-iframe-container" style="height:100%;left:0;position:absolute;top:0;width:100%;z-index:200">';
                videoHtml += '<span class="sr-only">' + dataAltText + '</span>';
                videoHtml += '<iframe width="560" title="' + dataIframeTitle + '" height="315" src="' + youtubeEmbedUrl + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width:100%;height:100%;"></iframe>';
                videoHtml += '</div>';
                videoHtml += ' </div>';
                videoHtml += '</div>';

                $(this).prepend(videoHtml);

            }
            else if (dataVideoProvider == "Wistia") {
                // ------------------- Wistia -------------------
                window._wq = window._wq || [];
                window._wq.push(function (W) {
                    W.consent(false);
                    log("*GDPR-essential: Wistia consent is set to", W.consent()); // returns true or false
                });

                var captionOptions = "";
                if (dataEnableCaptions != 'undefined' && dataEnableCaptions == 'True') {
                    captionOptions = "&plugin%5Bcaptions-v1%5D%5BonByDefault%5D=true";
                    //&plugin%5Bcaptions-v1%5D%5Blanguage%5D=ned
                }

                // WISTIA 
                log("*GDPR-essential: Wistia")
                loadExternalScript("//fast.wistia.net/assets/external/E-v1.js");
                // SK 28-07-2022 new: mobile video 
                var dataVideoCodeDeskTopOrMobile = isMobile() && dataVideoCodeMobile != '' ? dataVideoCodeMobile : dataVideoCode;
                var wistiaEmbedUrl = "https://fast.wistia.net/embed/iframe/" + dataVideoCodeDeskTopOrMobile + "?videoFoam=true" + captionOptions;
                var videoHtml = '';
                videoHtml += '<div class="content-wrapper content-wrapper-wistia-embed">';
                videoHtml += '<div class=wistia_responsive_padding>';
                videoHtml += '<div class=wistia_responsive_wrapper style="height:100%;left:0;position:absolute;top:0;width:100%;z-index:200">';
                videoHtml += '<span class="sr-only">' + dataAltText + '</span>';
                videoHtml += '<iframe src="' + wistiaEmbedUrl + '" title="' + dataIframeTitle + '" allowtransparency=true frameborder=0 scrolling=no class=wistia_embed name=wistia_embed allowfullscreen mozallowfullscreen=true webkitallowfullscreen=true oallowfullscreen=true msallowfullscreen=true width=100% height=100%></iframe>';
                videoHtml += '</div>';
                videoHtml += '</div>';
                videoHtml += '</div>';

                $(this).prepend(videoHtml);
            }
            else {
                // --------------------- Hosted Video -----------------

                var videoHtml = '';
                videoHtml += '<div class="video-wrapper-outer">';
                videoHtml += '<div class="video-wrapper-inner">';
                videoHtml += '<div class="video-iframe-container" style="height:100%;left:0;position:absolute;top:0;width:100%;z-index:200">';
                videoHtml += '<span class="sr-only">' + dataAltText + '</span>';
                videoHtml += '<video id="video" preload="auto" poster="' + dataVideoPlaceholderImageDesktop + '" loop="" autoplay="" muted="" volume="0.0" style="width:100%;height:100%;">';
                videoHtml += '<source src="' + dataVideoUrl + '" type="video/mp4"/>';
                videoHtml += 'Video not supported ';
                videoHtml += '</video>';
                videoHtml += '</div>';
                videoHtml += '</div>';
                videoHtml += '</div>';

                $(this).prepend(videoHtml);
            }

        }

    });


    $('.lazy-loading').appear();

    $('.lazy-loading').on('appear', function (event, $all_appeared_elements) {
        // this element is now inside browser viewport
        $all_appeared_elements.each(function () {
            LazyLoadImage(this);
            //if (($(this).attr('src') == undefined || $(this).attr('src').length == 0 || $(this).attr('src').indexOf('Spacer') > -1 || $(this).attr('src').indexOf('placeholder') > -1 || $(this).is("source")) && $(this).attr('data-src')) {
            //    log('Image loaded: ' + $(this).attr('data-src'));
            //    if (this.nodeName == 'DIV' || this.nodeName == 'PICTURE' || this.nodeName == 'LI') {
            //        $(this).css('background-image', 'url(' + $(this).attr('data-src') + ')')
            //    } else if ($(this).is("source")) {
            //        $(this).attr('srcset', $(this).attr('data-src'));
            //    } else {
            //        this.src = $(this).attr('data-src');
            //    }
            //}
        });
    });


    $('.content-wrapper-gallery').appear();
    $('.content-wrapper-gallery').one('appear', function (event, $all_appeared_elements) {
        new SwipeGallery('.swipe-base', '.gallery-item');
    });

    $.force_appear();


    $(".toggle-price-info-popup").click(function () {
        if ($(".price-info-popup").is(":visible")) {
            $(".price-info-popup").hide();
            $("button.toggle-price-info-popup-asterisk").attr("aria-expanded", "false");
        } else {
            $(".price-info-popup").show();
            $("button.toggle-price-info-popup-asterisk").attr("aria-expanded", "true");
        }
    });


    // momentum responsive carousel experiment
    $(".carousel-wrapper-responsive").each(function () {

        //log("found responsive carousel");

        // first show placeholder
        $(".placeholder").show();
        // hide carousel list old html
        $(".carousel-wrapper-responsive ul").html("");

        // get window width
        var myWindowWidth = $(window).width();

        // get total number of items
        var nrOfItems = jsonCarouselItems.length;

        // calculate number of items per slide
        var nrOfItemsPerSlide = myWindowWidth < 768 ? 1 : myWindowWidth < 1024 ? nrOfItemsPerSlide768 : nrOfItemsPerSlide1024;

        // how many slides for nr of tiles
        var nrOfSlides = Math.ceil(nrOfItems / nrOfItemsPerSlide);

        // create nr of lists
        var itemIndex = 0;
        var htmlToAppend = "";
        for (i = 1; i <= nrOfSlides; i++) {

            // start with <li> open tag and <div class='row'> for bootstrap grid
            var liHtml = "<li class='pane" + i + " regular'><div class='row'>";

            // add col divs
            for (s = 0; s < nrOfItemsPerSlide; s++) {
                // if index is above max then start again
                if (itemIndex >= nrOfItems) {
                    itemIndex = 0;
                }

                // debug
                //log("add item [" + itemIndex + "] to slide nr: " + i);

                // get item
                var objItem = jsonCarouselItems[itemIndex];
                var imageSrc = objItem.image;
                var title = objItem.title;

                // create div-html 
                var divHtml = "";
                divHtml += "<div class='col-tile col-lg-3 col-md-4 col-sm-6 col-xs-12'>";
                divHtml += "<div class='image'>";
                divHtml += "<img src='" + imageSrc + "'/>";
                divHtml += "</div>";
                divHtml += "<div class='text'>";
                divHtml += "<h3>" + title + "</h3>";
                divHtml += "</div>";
                divHtml += "</div>";

                // append to li
                liHtml += divHtml;

                // add up index
                itemIndex = itemIndex + 1;
            }

            liHtml += "</div></li>";

            // append to html
            htmlToAppend += liHtml;

        }

        // replace with clean li 
        $(".carousel-wrapper-responsive ul").html(htmlToAppend);

        // initalize
        var carouselBanner = new Carousel("#carousel-topsellers");
        carouselBanner.init();

        // hide placeholder
        $(".placeholder").hide();

    });


    //// GALLERY 360: keep focus inside modal
    //document.addEventListener('keydown', function (e) {

    //    if ($("body").hasClass("gallery-zoom-active")) {

    //        var focusedElement = document.activeElement;
    //        var focusedTabIndex = $(focusedElement).attr("tabindex");
    //        var firstFocusableElement = $("a.fancybox-close");
    //        var lastFocusableElement = $("a.fancybox-prev");
    //        var isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

    //        if (e.keyCode == 37)
    //        {
    //            $.fancybox.prev();
    //        }
    //        else if (e.keyCode == 39)
    //        {
    //            $.fancybox.next();
    //        }
    //        else if (!isTabPressed) {
    //            return;
    //        }

    //        if (e.shiftKey) {
    //            // shift tab (reverse)
    //            if (focusedTabIndex == "1") {
    //                // dan naar 3
    //                lastFocusableElement.focus();
    //                e.preventDefault();
    //            }
    //        } else {
    //            // tab
    //            if (focusedTabIndex == "3") {
    //                firstFocusableElement.focus();
    //                e.preventDefault();
    //            }
    //        }
    //    }
    //});

    //var modalInitiator;
    $('.modal').on('shown.bs.modal', function (e) {
        //A360 make sure all elements outside modal have tabindex -1 + set focus to close button 
        openDialog($(this)[0]);
        $(this)[0].querySelectorAll('button')[0].focus();
        //modalInitiator = e.relatedTarget;
    });
    $('.modal').on('hidden.bs.modal', function (e) {
        //A360 make sure all elements outside modal have tabindex -1
        closeDialog();
        //modalInitiator.focus();
        //Array.from(modalInitiator.querySelectorAll('*'))[0].focus();
        //$(modalInitiator)[0].querySelectorAll('*')[0].focus();
    });


    $('#closeDevtools').click(function () { Cookies.set('showDevToolsDefault', false); });
    $('#showDevtools').click(function () { Cookies.set('showDevToolsDefault', true); });

    // new zoom
    $("#mainImage button:not(.vue-enabled)").click(function () {

        var zoomImageSrc = $(this).attr("data-zoomsrc");
        // show zoom div
        $(".zoomed-image-modal").addClass("zoomed-image-modal-active");
        $(".zoomed-image-modal img").attr("src", zoomImageSrc);

        // add class to body
        $("body").addClass("bike-zoom-active");

        openDialog($(".zoomed-image-modal")[0]);

        setTimeout(function () {
            $("#close-zoomed-image-modal").focus();
        }, 1000);
    });

    $("button.close-zoomed-image-modal:not(.vue-enabled), img.zoomed-image:not(.vue-enabled)").click(function () {
        $(".zoomed-image-modal").removeClass("zoomed-image-modal-active");
        $(".zoomed-image-modal img").attr("src", "");
        // add class to body
        $("body").removeClass("bike-zoom-active");
        closeDialog();
        setTimeout(function () { $("#mainImage button").focus(); }, 1000);
    });

    $(".content-wrapper-topbaritems .item-trigger-button .trigger").click(function () {
        var triggerId = $(this).attr("data-triggerid");
        $("button#" + triggerId).trigger("click");
    });

    /* floating labels - blur and focus */
    $(".wrapper-floating-label:not(.vue-enabled) input").on('focus', function () {
        // FOCUS
        $(this).closest(".wrapper-floating-label").addClass("active");
        // always move label
        $(this).closest(".wrapper-floating-label").addClass("movelabel");

    }).bind('blur', function () {
        // BLUR
        $(this).closest(".wrapper-floating-label").removeClass("active");
        // move label
        var myValue = $(this).val();
        if (myValue == '') {
            $(this).closest(".wrapper-floating-label").removeClass("movelabel");
        }
    });

    // floating labels - on load
    $(".wrapper-floating-label:not(.vue-enabled) input").each(function () {
        var myValue = $(this).val();
        if (myValue != '') {
            $(this).parent().addClass("movelabel");
        }
    });

    $(".wrapper-floating-label:not(.vue-enabled) input").change(function () {
        var myValue = $(this).val();
        if (myValue != '') {
            $(this).parent().addClass("movelabel");
        }
    });

    /*
    $(".tile .article-linked").click(function () {
        var dataLinkUrl = $(this).attr("data-linkurl");
        var dataLinkTarget = $(this).attr("data-linktarget");
        // go
        window.open(dataLinkUrl, dataLinkTarget);
    });
    */

    $(function () {
        var numberOfImages = 36;
        var currentImagePosition = 5;
        var imgNumString = '';

        $("#rotate360ImgLeft").click(function () {
            if (currentImagePosition > 1) {
                currentImagePosition--;
            } else {
                currentImagePosition = numberOfImages;
            }

            if (currentImagePosition < 10) {
                imgNumString = '0' + currentImagePosition.toString();
            } else {
                imgNumString = currentImagePosition.toString();
            }

            // change the image src
            document.getElementById("myImg").src = imageFolder + "/" + imgNumString + "_" + imageFileNameBase + ".jpg";
        });

        $("#rotate360ImgRight").click(function () {
            if (currentImagePosition < numberOfImages) {
                currentImagePosition++;
            } else {
                currentImagePosition = 1;
            }

            if (currentImagePosition < 10) {
                imgNumString = '0' + currentImagePosition.toString();
            } else {
                imgNumString = currentImagePosition.toString();
            }

            // change the image src
            document.getElementById("myImg").src = imageFolder + "/" + imgNumString + "_" + imageFileNameBase + ".jpg";
        });
    });

    /* OLD SIZING WIZARD  */
    /*
    $(".sizingwizard button").click(function (e) {
        HandleSizingWizard(this);
    });
    
    $(".sizingwizard .unit-toggle a").click(function () {
        var newSizingUnit = $(this).attr("data-unit");
        // container
        $(".sizingwizard").removeClass("sizingwizard-cm sizingwizard-inch");
        $(".sizingwizard").addClass("sizingwizard-" + newSizingUnit);
    });
    
    $(".sizingwizard input.trigger-image").focus(function (event) {
        HandleSizingWizardImage(event);
    });
    
    $(".sizingwizard .trigger-image").click(function (event) {
        HandleSizingWizardImage(event);
    });
    */

    /* ADVANCED SIZING TOOL  */

    $("#advancedsizingtool button").click(function (e) {
        HandleAdvancedSizingTool(this);
    });

    $("#advancedsizingtool .radios label").click(function () {
        var labelId = $(this).attr("for");
        $(".radios label").removeClass("active");
        $(this).addClass("active");
    });

    $("#advancedsizingtool .length-input-wrapper input").keyup(function () {
        var inputValue = $(this).val();
        var newValue = inputValue.replace(/[^0-9.-]/g, '');
        $(this).val(newValue);
    });

    $("#advancedsizingtool .unit-toggle a").click(function () {
        var newSizingUnit = $(this).attr("data-unit");
        // empty all input values
        $("#advancedsizingtool input#bodylength_cm").val("");
        $("#advancedsizingtool input#bodylength_feet").val("");
        $("#advancedsizingtool input#bodylength_inch").val("");
        // hidden input
        $("#advancedsizingtool input#bodylengthunit").val(newSizingUnit.toUpperCase());
        // container
        $("#advancedsizingtool").removeClass("sizingtool-unit-cm sizingtool-unit-inch");
        $("#advancedsizingtool").addClass("sizingtool-unit-" + newSizingUnit);
        // link itself
        $("#advancedsizingtool .unit-toggle a").removeClass("active");
        $(this).addClass("active");
    });

    $("#advancedsizingtool .shoetype-toggle a").click(function () {
        // get new unif
        var newShoeType = $(this).attr("data-unit");
        // hidden input
        $("#advancedsizingtool input#shoesizetype").val(newShoeType.toUpperCase());
        // container
        $("#advancedsizingtool").removeClass("sizingtool-shoetype-us sizingtool-shoetype-uk sizingtool-shoetype-eu");
        $("#advancedsizingtool").addClass("sizingtool-shoetype-" + newShoeType);
        // link itself
        $("#advancedsizingtool .shoetype-toggle a").removeClass("active");
        $(this).addClass("active");

    });

    $("#advancedsizingtool").each(function () {
        PrefillSizingValuesFromCookie();
    });

    /* color select */

    $("#colorSelect").on('keydown', function (e) {
        // Helper function to check if an element has the id attribute
        function hasId(element) {
            return typeof element.id != 'undefined';
        }

        // Check if the target element is the sizes button we need.
        if (e.target.nodeName.toUpperCase() == 'BUTTON' && e.target.getAttribute('data-id') == 'colors') {
            // Check if the keyCode is enter (13), keyup (38) or keydown (40)
            if (e.keyCode == 13 || e.keyCode == 38 || e.keyCode == 40) {
                // Prevent default and stopPropagation so that the component itself isn't messing with us.
                e.preventDefault();
                e.stopPropagation();

                // Fire the click event. 
                e.target.click();

                // Find the first A element of the sizes dropdown. This is always id bs-select-2-1
                var firstAEl = $("#bs-select-1-1");
                // Focus on it. 
                firstAEl.focus();
            }
        }

        // Check if the target element is one of the values of the dropdown.
        if (e.target.nodeName.toUpperCase() == 'A' && hasId(e.target) && e.target.getAttribute('id').startsWith('bs-select')) {
            // Check if the keyCode is enter (13).
            if (e.keyCode == 13) {
                // Fire click event.
                e.target.click();
            }

            // Prevent tab since this is messing with our own logic.
            if (e.keyCode == 9) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    });
    $("#colorSelect").on('click keyup', function (e) {
        $("button[data-id='colors']").attr('aria-labelledby', 'colorsLabelSr colorsValueSr');
    });

    $("#partnumberSelect").on('keydown', function (e) {
        // Helper function to check if an element has the id attribute
        function hasId(element) {
            return typeof element.id != 'undefined';
        }

        // Check if the target element is the sizes button we need.
        if (e.target.nodeName.toUpperCase() == 'BUTTON' && e.target.getAttribute('data-id') == 'sizes') {
            // Check if the keyCode is enter (13), keyup (38) or keydown (40)
            if (e.keyCode == 13 || e.keyCode == 38 || e.keyCode == 40) {
                // Prevent default and stopPropagation so that the component itself isn't messing with us.
                e.preventDefault();
                e.stopPropagation();

                // Fire the click event. 
                e.target.click();

                // Find the first A element of the sizes dropdown. This is always id bs-select-2-1
                var firstAEl = $("#bs-select-2-1");
                // Focus on it. 
                firstAEl.focus();
            }
        }

        // Check if the target element is one of the values of the dropdown.
        if (e.target.nodeName.toUpperCase() == 'A' && hasId(e.target) && e.target.getAttribute('id').startsWith('bs-select')) {
            // Check if the keyCode is enter (13).
            if (e.keyCode == 13) {
                // Fire click event.
                e.target.click();
            }

            // Prevent tab since this is messing with our own logic.
            if (e.keyCode == 9) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    });
    $("#partnumberSelect").on('click keyup', function (e) {
        $("button[data-id='sizes']").attr('aria-labelledby', 'sizesLabelSr sizesValueSr');
    });
    $("#supplementaryItemSelect").on('keydown', function (e) {
        // Helper function to check if an element has the id attribute
        function hasId(element) {
            return typeof element.id != 'undefined';
        }

        // Check if the target element is the sizes button we need.
        if (e.target.nodeName.toUpperCase() == 'BUTTON' && e.target.getAttribute('data-id') == 'supplementaryItems') {
            // Check if the keyCode is enter (13), keyup (38) or keydown (40)
            if (e.keyCode == 13 || e.keyCode == 38 || e.keyCode == 40) {
                // Prevent default and stopPropagation so that the component itself isn't messing with us.
                e.preventDefault();
                e.stopPropagation();

                // Fire the click event. 
                e.target.click();

                // Find the first A element of the sizes dropdown. This is always id bs-select-2-1
                var firstAEl = $("#bs-select-3-1");
                // Focus on it. 
                firstAEl.focus();
            }
        }

        // Check if the target element is one of the values of the dropdown.
        if (e.target.nodeName.toUpperCase() == 'A' && hasId(e.target) && e.target.getAttribute('id').startsWith('bs-select')) {
            // Check if the keyCode is enter (13).
            if (e.keyCode == 13) {
                // Fire click event.
                e.target.click();
            }

            // Prevent tab since this is messing with our own logic.
            if (e.keyCode == 9) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    });

    $("#supplementaryItemSelect").on('click keyup', function (e) {
        $("button[data-id='supplementaryItems']").attr('aria-labelledby', 'supplementaryLabelSr supplementaryValueSr');
    });


    /* sticky configurator */
    $(".container-configurator-with-addons").each(function () {

        var isMobile = $("header .hamburger").is(":visible");
        if (isMobile) {
            // not sticky 
        } else {
            // sticky
            var positionOfElement = $("#text h1").offset() != undefined ? $("#text h1").offset().top : 0, $window = $(window);
            positionOfElement = positionOfElement - 50;
            $window.scroll(function () {
                if ($window.scrollTop() > positionOfElement) {
                    $(".container-configurator-with-addons").addClass("container-sticky-image");
                } else {
                    $(".container-configurator-with-addons").removeClass("container-sticky-image");
                }
            });
        }

    });

    $('#catalogueFilterByLocationForm').submit(function (e) {

        if ($('#catalogueLatitude').val() == '' || $('#catalogueLongitude').val() == '') {
            e.preventDefault();
            if ($('#catalogueKeyword').val().length > 0) {
                $('#btnCatalogueFilterByLocation').trigger('click');
            }
        }
    })

    //$('#catalogueKeyword').on('keyup', function (e) {
    //    e.preventDefault();
    //    if (e.keyCode == 13) {
    //        $('#btnCatalogueFilterByLocation').trigger('click');
    //    }
    //});

    $('#btnCatalogueFilterByLocation').on('click', function (e) {
        if ($('#catalogueKeyword').val().length > 0) {
            //GA tracker
            trackEventGlobalAndLocal('Find bike near me', 'Post', window.location.href + ': ' + $('#catalogueKeyword').val());

            getLatLng($('#catalogueKeyword').val() + ' ' + country, function (lat, lng) {
                if (lat != undefined) {
                    $('#catalogueLatitude').val(lat);
                    $('#catalogueLongitude').val(lng);
                    $('#catalogueFilterByLocationForm').submit();
                } else {
                    //try to find without country suffix
                    getLatLng($('#catalogueKeyword').val(), function (lat, lng) {
                        if (lat != undefined) {
                            $('#catalogueLatitude').val(lat);
                            $('#catalogueLongitude').val(lng);
                            $('#catalogueFilterByLocationForm').submit();
                        } else {
                            //no location found
                            $('#catalogueFilterByLocationForm').append('<div class="alert alert-danger">' + lblNoLocationFound + '</span>');
                        }
                    })
                }
            })
        }
    });

    $('#btnCatalogueFilterByBrowser').on('click', function () {
        getBrowserLocation(function (lat, lng, keyword, failed) {
            $('#catalogueLatitude').val(lat);
            $('#catalogueLongitude').val(lng);
            if (failed == undefined || failed == false) {
                $('#catalogueKeyword').val(keyword);
            } else {
                $('#catalogueKeyword').val('');
            }

            //GA tracker
            trackEventGlobalAndLocal('Find bike near me', 'Post', window.location.href + ': ' + keyword);

            $('#catalogueFilterByLocationForm').submit();
        });
    });

    $('.santander-learn-more').click(function (event) {
        if ($(this).attr('data-price').length > 0) {
            $.ajax({
                type: "GET",
                url: "/" + culture + "/shop/santander-calculator-url?price=" + $(this).attr('data-price'),
                success: function (response) {
                    if (response.length > 0) {
                        $('#iframeSantanderCalculator').attr('src', response);
                        $('#loadingLabel').hide();
                        $('#iframeSantanderCalculator').show();
                    } else {
                        $('#loadingLabelError').show();
                        $('#loadingLabel').hide();
                    }
                }
            })
        }
    });

    $('.youtube-player').each(function () {
        var preferences = $.gdprcookie.preference();
        if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1 && $(this).attr('data-src') != undefined && $(this).attr('data-src').length > 0) {
            //consent given. Set src
            $(this).attr('src', $(this).attr('data-src'));
        }
    });

    $('.youtubeButtonConsent').click(function () {
        //set cookies
        var d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
        document.cookie = window.GDPRcookieName + "=[\"essential\",\"statistics\",\"marketing\"];expires=" + d.toUTCString() + ";path=/" + window.culture;

        //reload page
        parent.location.reload();
    });


    $(".giftcard-amounts label").each(function () {
        // needed for a360 focus indicators on labels
        $(this).focusin(function () {
            $(this).addClass("focused");
        });
        $(this).focusout(function () {
            $(this).removeClass("focused");
        });
    });

    $('#google-reviews-badge').each(function () {
        var preferences = $.gdprcookie.preference();
        if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
            var _element = $(this);
            var id = _element.data("id");
            _element.append("<div id='map'></div>");
            var map = new google.maps.Map(document.getElementById('map'));

            var request = {
                placeId: id
            };

            var service = new google.maps.places.PlacesService(map);
            service.getDetails(request, function (place, status) {

                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    var myRating = place.rating;
                    var roundedRatingNumber = Math.round(myRating * 2) / 2;
                    var imageSuffix = roundedRatingNumber.toString().replace(".", "-");
                    var starsImagesUrl = cdn + "/Images/Shared/google-review-stars-" + imageSuffix + ".png";
                    // get elements
                    _element.find('#stars-image').attr('src', starsImagesUrl);
                    _element.find('#rating').html(myRating);
                    _element.find('#number-of-reviews').html(place.user_ratings_total);
                    $("#google-reviews-badge").bind("click", function () {
                        window.location = place.url;
                    });
                    _element.show();
                }
            });
        }
    });



    /* NEW: filter tooltips */
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').on('show.bs.tooltip', function () {
        // Only one tooltip should ever be open at a time
        $('.tooltip').not(this).hide();
    });

    /* NEW: filter toggle on mobile */
    $("a.toggle-filters").click(function () {
        $("body").toggleClass("disable-scrollbar");
        $(".content-wrapper-catalogue").toggleClass("filters-active");
    });


    //$('#devtools-js-consolelog-active').each(function () {
    //    var that = $(this);
    //    var url = window.isDeveloperMachine ? 'http://localhost:50890/get-js-consolelog' : 'https://www.giant-bicycles.com/get-js-consolelog';
    //    $.ajax({
    //        type: 'GET',
    //        url: url,
    //        success: function (data) {
    //            that.Html(data);
    //        }
    //    });
    //});

    $(document).on('click', '.track-GA4-event', function () {
        trackEventGlobalAndLocalGA4($(this).data());
    });

    $(document).on('change', '.track-GA4-event, .toggle-sort', function () {
        var arr = $(this).data();
        arr['ga4_click_text'] = $("option:selected", this).text().length > 0 ? $("option:selected", this).text() : $(this).val();
        trackEventGlobalAndLocalGA4(arr);
    });

    $('.track-GA4-event-view').each(function () {
        trackEventGlobalAndLocalGA4($(this).data());
    });

    /*Speciale trigger om GA4 te triggeren door bootstrap-select */
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.attributeName === "class") {
                if ($(mutation.target).hasClass('open')) {
                    var arr = $('#sizes').data();
                    arr['ga4_name'] = 'drop_down_size';
                    trackEventGlobalAndLocalGA4(arr);
                }
            }
        });
    });
    setTimeout(function () {
        $('#sizes').each(function () {
            observer.observe($('#sizes').parent()[0], {
                attributes: true
            });
        })
    }, 5000);

    $('.p-w-r').each(function () {
        log('tst');
    })

    /*Dit aanzetten om het Salesforce chat iframe enkel te tonen indien er consent is gegeven */
    //$('#chatFrame').each(function () {
    //    var preferences = $.gdprcookie.preference();
    //    if (preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
    //        $(this).attr('src', $(this).data("src"));
    //    }
    //});


    $("a.toggle-subnavigation").click(function () {

        // get id
        var mainId = $(this).attr("data-main-id");
        var subId = $(this).attr("data-sub-id");
        var isAlreadyOpen = $("#" + mainId).is(":visible");
        var isMobileMenu = isMobile();

        // hide all at first
        $(".subnavigation").hide();

        // open
        if (isMobileMenu && isAlreadyOpen) {
            // hide
        } else {
            $("#" + mainId).show();
        }

        // activate
        if (subId != null && subId != undefined) {
            activateProductSubCategory(subId);
        }

        // is bike menu open?
        var isBikesMenuOpen = $("#bikes").is(":visible");
        if (isBikesMenuOpen) {
            $("nav#menu ul").addClass("bikes-menu-open");
        } else {
            $("nav#menu ul").removeClass("bikes-menu-open");
        }

    });


    $("a.toggle-product-subcategory").on('click mouseover', function (event) {

        if (event.type == "mouseover" && isMobile()) {
            // do nothing this is causing trouble
        } else {
            // get id
            var divId = $(this).attr("data-id");

            // activate
            activateProductSubCategory(divId);
        }
    });

    $('#LoadLeaselinkScripts').each(function () {
        var preferences = $.gdprcookie.preference();
        if (preferences.indexOf('essential') > -1 && preferences.indexOf('statistics') > -1 && preferences.indexOf('marketing') > -1) {
            loadExternalScript('https://rep.leaselink.pl/plugin/LeaseLinkLib.min.js');
            loadExternalScript('https://rep.leaselink.pl/plugin/GPL001.js');
        }
    });

    $('#totaltimecatalogue').click(function () {
        $('#details-totaltimecatalogue').show();
    });

});

